import { Box, Spinner, Tab, Text, Tooltip } from '@chakra-ui/react'
import { useSelectedReportType } from '../../pages/reports'
import {
  DOPPEL_DARK_SECONDARY,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '../../utils/style'
import { ReportStatus, getStatusLabel } from '../../utils/constants'

const ReportStatusTab = ({ reportStatus, count, isLoading }) => {
  const [selectedReportType] = useSelectedReportType()
  const isInternal = reportStatus.toLowerCase().includes('internal')
  const tooltipLabel = getHeaderTooltip(reportStatus)

  const [bgColor, textColor, pillBgColor] = getTabColor(isInternal)

  const content = (
    <Tab bgColor={bgColor} key={'report_tab' + reportStatus} textColor={textColor}>
      <Text fontSize={13} fontWeight="semibold">
        {getStatusLabel(reportStatus, selectedReportType)}
      </Text>

      <Box
        bgColor={pillBgColor}
        borderRadius="full"
        marginLeft="2"
        minWidth="55px"
        paddingX="2"
      >
        {isLoading ? <Spinner size="xs" /> : count}
      </Box>
    </Tab>
  )
  if (!tooltipLabel) {
    return content
  }
  return (
    <Tooltip aria-label={tooltipLabel} label={tooltipLabel}>
      {content}
    </Tooltip>
  )
}

function getTabColor(isInternal) {
  let bgColor = 'transparent'
  let textColor = DOPPEL_WHITE
  let pillBgColor = DOPPEL_DARK_SECONDARY

  if (isInternal) {
    bgColor = DOPPEL_INTERNAL_PURPLE
    textColor = DOPPEL_WHITE
    pillBgColor = DOPPEL_DARK_SECONDARY
  }

  return [bgColor, textColor, pillBgColor]
}

function getHeaderTooltip(reportStatus) {
  if (
    reportStatus == ReportStatus.APPROVED_DERIVATIVE ||
    reportStatus == ReportStatus.ENUM_APPROVED_DERIVATIVE
  ) {
    return 'Incidents that have been flagged to monitor, but don’t need immediate actioning.'
  }
  return null
}

export default ReportStatusTab
