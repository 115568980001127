// react component that renders a button that links to rubric link for the customer and only shows to employees

import { Button, Link } from '@chakra-ui/react'
import { getOrgData, useIsEmployeeView, useOrgID } from '../../hooks/id_token_claims'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'

export default function CustomerRubricLink() {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [isEmployeeView] = useIsEmployeeView()
  if (!isEmployeeView || !orgData?.rubric_link) {
    return null
  }
  return (
    <Link
      as={Button}
      bgColor={DOPPEL_INTERNAL_PURPLE}
      fontSize="13"
      marginRight="4"
      onClick={() => {
        window.open(orgData.rubric_link, '_blank')
      }}
      paddingX="2"
      size="sm"
      textColor={DOPPEL_WHITE}
      variant="outline"
    >
      Customer Rubric
    </Link>
  )
}
