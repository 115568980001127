import { HStack, Text } from '@chakra-ui/react'

import DoppelPrimaryButton from '../shared/doppel_primary_button'

const StatusChangeButton = ({
  text,
  statusTo,
  selectedRows,
  actionLoadingState,
  handleClick,
  ...props
}) => {
  return (
    <DoppelPrimaryButton
      disabled={selectedRows.length == 0}
      fontSize={12}
      h={'30px'}
      isLoading={actionLoadingState?.status === statusTo}
      onClick={() => handleClick()}
      {...props}
    >
      <HStack>
        <Text fontSize={11}>{'Bulk ' + text}</Text>
      </HStack>
    </DoppelPrimaryButton>
  )
}

export default StatusChangeButton
