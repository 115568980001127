import { RepeatIcon } from '@chakra-ui/icons'
import DoppelDefaultButton from '../shared/doppel_default_button'

const RefreshButton = ({ refreshFunc }) => {
  return (
    <DoppelDefaultButton
      leftIcon={<RepeatIcon />}
      marginLeft={5}
      onClick={() => {
        refreshFunc()
      }}
    >
      Refresh
    </DoppelDefaultButton>
  )
}

export default RefreshButton
